<!--<app-header-one [hidden]="true" (resultSearch)="filter($event)"  [sticky]="true"></app-header-one>-->
<app-header-four (resultSearch)="filter($event)" [sticky]="true"></app-header-four>

<!-- Home slider start-->
<section #boxSearch class="p-0 height-85 tools_slider">
    <owl-carousel-o [options]="HomeSliderConfig" class="home-slider">
        <ng-container *ngFor="let item of homeSlider">
            <ng-template carouselSlide>
                <img [src]="item.url" class="img-fluid" alt="">
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</section>
<!-- Home slider End -->

<!-- about section start-->
<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="title3">
                    <h2 class="title-inner3" [textContent]="titleWelcome"></h2>
                    <div class="line"></div>
                </div>
                <div class="about-text">
                    <p [textContent]="messageWelcome"></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->

<!-- search section start-->
<!--<div *ngIf="userSearch" class="title1 title-gradient section-t-space">
    <h2 class="title-inner1">Resultados</h2>
</div>
<section class="p-t-0 section-b-space gym-product">
    <div *ngIf="userSearch" class="container">
        <div class="row partition-cls">
            <div class="col-xl-3 col-sm-6 d-grid my-2" *ngFor="let product of resultSearch">
                <div class="product-box d-grid align-content-center">
                    <app-product-box-one
                            [product]="product"
                            [loader]="true"
                            [currency]="productService?.Currency"
                            [cartModal]="true">
                    </app-product-box-one>
                </div>
            </div>
            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!resultSearch.length">
                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                <h3>¡Lo sentimos! No se ha encontrado el producto que buscaba. </h3>
                <a [routerLink]="['/products']" class="btn btn-solid">seguir comprando</a>
            </div>
        </div>
    </div>
</section>-->
<!-- search section end-->

<!-- category -->
<section class="pt-0 category-tools">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-collection [class]="'pt-0'" [categories]="categories" [category]="'tools'">
                </app-collection>
            </div>
        </div>
    </div>
</section>
<!-- category end -->

<!--<button [hidden]="true" (click)="updateCategories()" class="btn" >-->
<!--    click-->
<!--</button>-->
<!--<button [hidden]="true" (click)="getProducts()" class="btn btn-solid" >-->
<!--    get products-->
<!--</button>-->
<!--<a class="btn" href="chrome://www.google.com">Abrir en Google Chrome</a>-->


<!-- parallax section start -->
<section class="full-banner parallax-banner21 parallax small-slider tools-parallax-product" [ngStyle]="{'background-image': 'url('+ bannerParallax +')'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-12 tools-grey">
                <owl-carousel-o [options]="ProductSliderConfig" class="tools-product-4 product-m">
                    <ng-container *ngFor="let product of newProducts">
                        <ng-template carouselSlide>
                            <div class="product-box product-wrap d-grid">
                                <app-product-box-three [product]="product" [currency]="productService?.Currency"></app-product-box-three>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- parallax section end -->

<!-- product slider start -->
<section class="section-b-space tools-grey">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title3">
                    <h2 class="title-inner3">Productos Destacados</h2>
                    <div class="line"></div>
                </div>
                <owl-carousel-o [options]="ProductSliderConfig" class="product-5 product-m no-arrow">
                    <ng-container *ngFor="let product of products">
                        <ng-template carouselSlide>
                            <div class="product-box product-wrap d-grid">
                                <app-product-box-three [product]="product" [currency]="productService?.Currency">
                                </app-product-box-three>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- product slider end -->

<!-- product section start -->
<!--<section [hidden]="true" class="section-b-space tools_product">-->
<!--    <div class="container">-->
<!--        <div class="row multiple-slider">-->
<!--            <div class="col-xl-12 col-lg-12 col-md-12">-->
<!--                <app-product-box-vertical-slider [title]="'new products'" [type]="'tools'">-->
<!--                </app-product-box-vertical-slider>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<!-- product section end -->

<!--  logo section start-->
<!--<section [hidden]="true" class="section-b-space tools-brand">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-md-12">-->
<!--                <app-logo [logos]="logos"></app-logo>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<!--  logo section End-->

<!-- Cart Open To Top Side-->
<app-cart-variation [direction]="'top'"></app-cart-variation>
<app-footer-one [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-footer-one>
