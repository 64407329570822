import {Component, OnInit} from '@angular/core';
import {NavService, Menu} from '../../services/nav.service';
import {Router} from '@angular/router';
import {HttpService} from '../../../services/http.service';
import {FirestoreService} from '../../../services/firestore.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    public menuItems: Menu[];
    public fontColor: string = '';

    constructor(
        private router: Router,
        private service: HttpService,
        private firestore: FirestoreService,
        public navServices: NavService
    ) {
        // this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
        this.router.events.subscribe((event) => {
            this.navServices.mainMenuToggle = false;
        });
        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            (res.header.menuCategories) ? this.getMenuCategories() : this.menuItems = res.header.main_menu;
            this.fontColor = res.header.fontColor;
        });

    }

    ngOnInit(): void {
    }

    mainMenuToggle(): void {
        this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
        item.active = !item.active;
    }

    getChildren() {
        this.firestore.getAll('Categories').subscribe(res => {
            this.menuItems.forEach(element => {
                if (element.megaMenu && element.collection != '') {
                    res.forEach(item => {
                        if (item.category_active) {
                            item.title = item.category_name;
                            item.active = false;
                            item.type = 'link';
                            item.path = `products/${item.category_active}`;
                            // element.children.push(item);
                        }
                    });
                    // element.children.length = 10;
                }
                console.info(this.menuItems);
            });
        });
    }

    getMenuCategories() {
        this.service.getCategories().subscribe(res => {
            this.menuItems = [];
            let limit = window.innerWidth > 1400 ? 92 : 80;
            let lengthCategories = 0;
            if (res.length > 0) {
                let categoriesItems = res.sort((a, b) => {
                    if (a.category_order < b.category_order) return -1;
                    else if (a.category_order > b.category_order) return 1;
                    return 0;
                });
                categoriesItems.forEach(item => {
                    if (item.category_active) {
                        lengthCategories += item.category_name.length;
                        item.title = item.category_name;
                        item.type = 'link';
                        item.path = `/products/${item.url_category}`;
                        if (lengthCategories <= limit ) {
                            // this.service.getCategoryByUrl(item.url_category).subscribe(response => {
                            //     if (response.success && response.subcategories.length > 0) {
                            //         item.path = `/products/${response.category.url_friendly}`;
                            //         item.type = 'sub';
                            //         item.active = false;
                            //         item.children = [];
                            //         response.subcategories.forEach(element => {
                            //             element.title = element.name;
                            //             element.type = 'link';
                            //             element.path = `${item.path}/${element.url}`;
                            //             item.children.push(element);
                            //         });
                            //     }
                            // });
                            this.menuItems.push(item);
                        }
                    }
                });
            }
            if (lengthCategories > limit ) {
                let item = {
                    title: 'Todas las categorias',
                    type: 'link',
                    path: '/products'
                };
                this.menuItems.push(item);
            }
        });
    }

}
